/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";

.page-header{
  margin-top: 0;
  h1 {
    font-size: 4em;
    padding-bottom: 10px;
  }
}

// Navbar =====================================================================

.navbar {
  border: none;
  font-size: 13px;
  font-weight: 300;

  .navbar-toggle:hover .icon-bar {
    background-color: #b3b3b3;
  }

  &-collapse {
    border-top-color: @dropdown-divider-bg;
    .box-shadow(none);
  }

  .btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .dropdown {

    &-menu {
      border: none;

      > li > a,
      > li > a:focus {
        background-color: transparent;
        font-size: 13px;
        font-weight: 300;
      }
    }

    &-header {
      color: rgba(255, 255, 255, 0.5);
    }

  }

  &-default {

    .dropdown-menu {
      background-color: @navbar-default-bg;

      > li > a,
      > li > a:focus {
        color: @navbar-default-color;
      }

      > li > a:hover,
      > .active > a,
      > .active > a:hover {
        background-color: @navbar-default-link-hover-bg;
      }
    }
  }

  &-inverse {

    .dropdown-menu {
      background-color: @navbar-inverse-bg;

      > li > a,
      > li > a:focus {
        color: @navbar-inverse-color;
      }

      > li > a:hover,
      > .active > a,
      > .active > a:hover {
        background-color: @navbar-inverse-link-hover-bg;
      }
    }
  }
}

// Buttons ====================================================================

.btn {
  padding: 14px 28px;

  &-lg {
    padding: 16px 32px;
  }

  &-sm {
    padding: 8px 16px;
  }

  &-xs {
    padding: 4px 8px;
  }
}

.btn-group {

  .btn ~ .dropdown-toggle {
    padding-left: 16px;
    padding-right: 16px;
  }

  .dropdown-menu {
    border-top-width: 0;
  }

  &.dropup .dropdown-menu {
    border-top-width: 1px;
    border-bottom-width: 0;
    margin-bottom: 0;
  }

  .dropdown-toggle {

    &.btn-default ~ .dropdown-menu {
      background-color: @btn-default-bg;
      border-color: @btn-default-border;

      > li > a {
        color: @btn-default-color;
      }

      > li > a:hover {
        background-color: darken(@btn-default-bg, 8%);
      }
    }

    &.btn-primary ~ .dropdown-menu {
      background-color: @btn-primary-bg;
      border-color: @btn-primary-border;

      > li > a {
        color: @btn-primary-color;
      }

      > li > a:hover {
        background-color: darken(@btn-primary-bg, 8%);
      }
    }

    &.btn-success ~ .dropdown-menu {
      background-color: @btn-success-bg;
      border-color: @btn-success-border;

      > li > a {
        color: @btn-success-color;
      }

      > li > a:hover {
        background-color: darken(@btn-success-bg, 8%);
      }
    }

    &.btn-info ~ .dropdown-menu {
      background-color: @btn-info-bg;
      border-color: @btn-info-border;

      > li > a {
        color: @btn-info-color;
      }

      > li > a:hover {
        background-color: darken(@btn-info-bg, 8%);
      }
    }

    &.btn-warning ~ .dropdown-menu {
      background-color: @btn-warning-bg;
      border-color: @btn-warning-border;

      > li > a {
        color: @btn-warning-color;
      }

      > li > a:hover {
        background-color: darken(@btn-warning-bg, 8%);
      }
    }

    &.btn-danger ~ .dropdown-menu {
      background-color: @btn-danger-bg;
      border-color: @btn-danger-border;

      > li > a {
        color: @btn-danger-color;
      }

      > li > a:hover {
        background-color: darken(@btn-danger-bg, 8%);
      }
    }
  }
}

// Typography =================================================================

.lead {
  color: @gray;
}

cite {
  font-style: italic;
}

blockquote {
  border-left-width: 1px;
  color: @gray;

  &.pull-right {
    border-right-width: 1px;
  }

  small {
    font-size: @font-size-small;
    font-weight: 300;
  }
}

// Tables =====================================================================

table {
  font-size: @font-size-small;
}

// Forms ======================================================================

input,
.form-control {
  padding: 7px;
}

label,
.control-label,
.help-block,
.checkbox,
.radio {
  font-size: @font-size-small;
  font-weight: normal;
}

.input-group-addon,
.input-group-btn .btn {
  padding: 8px 14px;
  font-size: @font-size-small;
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.nav-tabs {
  > li > a {
    background-color: @btn-default-bg;
    color: @text-color;
  }

  .caret {
    border-top-color: @text-color;
    border-bottom-color: @text-color;
  }
}

.nav-pills {
  font-weight: 300;
}

.breadcrumb {
  border: 1px solid @table-border-color;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
}

.pagination {
  font-size: @font-size-small;
  font-weight: 300;
  color: @gray-light;

  > li {
    > a,
    > span {
      margin-left: 4px;
      color: @gray-light;
    }
  }

  > .active {
    > a,
    > span {
      color: #fff;
    }
  }

  > li,
  > li:first-child,
  > li:last-child {
    > a,
    > span {
      border-radius: 3px;
    }
  }

  &-lg > li > a {
    padding-left: 22px;
    padding-right: 22px;
  }

  &-sm > li > a {
    padding: 0 5px;
  }
}

.pager {
  font-size: @font-size-small;
  font-weight: 300;
  color: @gray-light;
}

.list-group {
  font-size: @font-size-small;
  font-weight: 300;
}

// Indicators =================================================================

.close {
  opacity: 0.4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.alert {
  font-size: @font-size-small;
  font-weight: 300;

  a,
  .alert-link {
    font-weight: normal;
    color: #fff;
    text-decoration: underline;
  }

  .close {
    color: #fff;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.label {
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0;
  font-weight: 300;

  &-default {
    background-color: @btn-default-bg;
    color: @btn-default-color;
  }
}

.badge {
  font-weight: 300;
}

// Progress bars ==============================================================

.progress {
  height: 22px;
  padding: 2px;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  .box-shadow(none);
}

// Containers =================================================================

.dropdown {

  &-menu {
    padding: 0;
    margin-top: 0;
    font-size: @font-size-small;

    > li > a {
      padding: 12px 15px;
    }
  }

  &-header {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 9px;
    text-transform: uppercase;
  }
}

.popover {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

.panel {
  &-heading,
  &-footer {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

a {
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.sidebar {
  background-color: #383838;
  width: 250px;

  position:fixed;

  padding-top: 20px;
  top:0;
  left:0;
  bottom:0;

  .mobius-logo-wrapper {
    position: absolute;
    height: 126px;
    a {
      display: inline-block;
      padding:35px;
      margin-bottom:20px;
    }
  }

  .nav-wrapper {
    height: 100%;
    padding-top:126px;
    ul {
      list-style:none;
      padding: 0;
    }
    ul.nav {
      overflow: auto;
      height: 100%;
      a {
        display: block;
        padding-left: 35px;
        padding-right: 0;
      }
      > li {
        margin-bottom: 5px;
      }

      li[data-active="false"][data-active-ancestor="false"] {
        ul {

          display: none;
        }
      }

      li a {
        padding-left: 45px;
      }
      > li > a {
        color: white;
        background-color: #242424;
        font-size: 16px;
      }
      > li > ul {
        background-color: #383838;
        padding-bottom: 20px;
      }

      > li > ul > li {
        &:hover {
          background-color:#242424;
        }
      }

      > li > ul > li > a {
        color: #f3f3f3;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top:8px;
        font-size: 18px;

      }
      > li > ul > li > ul > li > a {
        padding-top: 2px;
        padding-bottom: 2px;
        color: #acacac;
      }
    }
  }
}

.container-fluid {
  max-width: @screen-lg-min;
}

h1,h2,h3,h4,h5 {
  margin-top: 0;
  margin-bottom:0;
}

.content-wrapper {
  margin-left: 250px;
  padding: 45px 0;
  @media (min-width: @screen-lg-min)
  {
    padding: 45px;
  }
  @media (min-width:@screen-md-min) and ( max-width: @screen-lg-min)
  {
    padding: 45px;
  }
}

[class^="icon-"] {
  font-size: 1.3em;
  margin-right: 10px;
  vertical-align: text-top;
  min-width: 1em;
  display: inline-block;
}

.glyphicon {
  font-size: 1.3em;
  margin-right: 10px;
  vertical-align: text-top;
  min-width: 1em;
  display: inline-block;
}